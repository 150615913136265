<template>
	<div class="flex flex-col relative">
		<div class="absolute right-0 -top-14">
			<div class="flex items-center relative">
				<!-- <div class="checkbox absolute left-0 -top-6">
					<label class="flex text-sm leading-4 font-normal text-gray-500">
						<input type="checkbox" @change="getRandom()" v-model="params.isRandom600" class="mr-2" />
						<p>Получить выборку 600 анкет</p>
					</label>
				</div> -->
				<Button text="Таблица для bus.gov.ru" class="mr-2" color="white" @click.native="downloadBusGovExcel()" :disabled="loadToExcell" :load="loadToExcell" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="openExport = true" />
			</div>
		</div>
		<div class="t-overflow rounded-tr-lg overflow-x-auto h-auto bg-white filter shadow-default" style="height: calc(100vh - 410px)">
			<table class="table-fixed w-full" :style="data.length > 0 ? 'min-height: 550px' : ''">
				<colgroup>
					<col style="width: 3.9rem" />
					<col style="min-width: 18.8rem" />
					<col style="width: 6.5rem" />
					<col style="width: 6.9rem" />
					<col style="width: 7.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
					<col style="width: 3.8rem" />
				</colgroup>
				<thead>
					<tr class="border-t border-gray-200 tr1_sticky">
						<th rowspan="3" class="p-4 border-b border-r border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider">П/П</th>
						<th
							v-for="(item, ind) in fields_sticky"
							:key="'J' + ind"
							rowspan="3"
							class="p-4 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider"
						>
							<span class="flex relative">
								<span
									:class="{ 'border-b border-gray-200 cursor-pointer': ind !== 1, 'text-blue-200 underline': filterBy === ind }"
									@click="ind !== 1 ? switchFilterVisibility(ind) : ''"
								>
									{{ item.Name }}
								</span>
								<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
								<!-- Фильтр и сортировка -->
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									position="left"
									:filterOpen="filterOpen"
									:id-filter="ind"
									:param="params"
									@query="getData"
									:fields="fields_sticky"
									:item="item"
								/>
							</span>
						</th>
						<th colspan="20" class="px-6 py-3 border-r border-b border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider">
							<p>Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка</p>
						</th>
					</tr>
					<tr class="tr2_sticky">
						<th
							v-for="(item, ind) in subFields"
							:key="'K' + ind"
							colspan="4"
							class="p-4 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider"
						>
							<p>{{ item }}</p>
						</th>
					</tr>
					<tr class="tr3_sticky">
						<th
							v-for="(item, ind) in fields"
							:key="'L' + ind"
							class="p-4 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider"
							:class="{ 'bg-lavender': columnHasGeneralIndicator(item) }"
						>
							<p>{{ item }}</p>
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, ind) in data" :key="'A2' + ind" class="bg-white">
					<tr :class="{ 'bg-gray-30': item.medicalOrgId === opened, 'cursor-pointer': item.hasBranch }" @click="openRow(item)">
						<td :rowspan="getRowsCount(item)" class="text-center font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
							<span v-if="params.currentPage === 0">
								{{ ind + 1 }}
							</span>
							<span v-if="params.currentPage > 0">
								{{ params.currentPage * rowsVisible + (ind + 1) }}
							</span>
						</td>
						<td :rowspan="getRowsCount(item)" class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<div class="flex items-center mb-2">
								<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
								<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
							</div>
							<p :class="{ underline: item.hasBranch }">{{ item.name }}</p>
						</td>
					</tr>
					<tr v-if="item.ambulator" :class="{ 'bg-gray-30': item.medicalOrgId === opened }">
						<td class="p-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">А</td>

						<td class="p-4 text-sm text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_Result }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_Count }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_1_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_1_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_1_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_2_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_2_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_2_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_3 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_3_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_3_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_3_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_4 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_4_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_4_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_4_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_5 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_5_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_5_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_5_3 }}</p>
						</td>
					</tr>
					<tr v-if="item.stacionar" :class="{ 'bg-gray-30': item.medicalOrgId === opened }">
						<td class="p-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">С</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_Result }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_Count }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_1_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_1_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_1_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_2_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_2_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_2_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_3 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_3_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_3_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_3_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_4 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_4_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_4_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_4_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_5 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_5_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_5_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_5_3 }}</p>
						</td>
					</tr>
					<tr v-if="item.healthResort" :class="{ 'bg-gray-30': item.medicalOrgId === opened }">
						<td class="p-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">К</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_Result }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_Count }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_1_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_1_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_1_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_2_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_2_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_2_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_3 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_3_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_3_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_3_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_4 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_4_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_4_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_4_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_5 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_5_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_5_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_5_3 }}</p>
						</td>
					</tr>
					<tr v-if="item.psychiatric" :class="{ 'bg-gray-30': item.medicalOrgId === opened }">
						<td class="p-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">П</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_Result }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_Count }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_1_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_1_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_1_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_2_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_2_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_2_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_3 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_3_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_3_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_3_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_4 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_4_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_4_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_4_3 }}</p>
						</td>

						<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_5 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_5_1 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_5_2 }}</p>
						</td>
						<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_5_3 }}</p>
						</td>
					</tr>
					<tr class="row-total">
						<td class="font-bold p-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">Итог</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_Result }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_Count }}</p>
						</td>

						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_1 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_1_1 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_1_2 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_1_3 }}</p>
						</td>

						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_2 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_2_1 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_2_2 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_2_3 }}</p>
						</td>

						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_3 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_3_1 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_3_2 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_3_3 }}</p>
						</td>

						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_4 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_4_1 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_4_2 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_4_3 }}</p>
						</td>

						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_5 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_5_1 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_5_2 }}</p>
						</td>
						<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_5_3 }}</p>
						</td>
					</tr>
					<tr v-if="opened === item.medicalOrgId">
						<td colspan="25" class="bg-gray-20">
							<table class="w-full table-fixed">
								<colgroup>
									<col style="width: 3.9rem" />
									<col style="min-width: 18.8rem" />
									<col style="width: 6.5rem" />
									<col style="width: 6.9rem" />
									<col style="width: 7.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
									<col style="width: 3.8rem" />
								</colgroup>
								<tbody v-for="(subitem, indx) in item.branches" :key="'N' + indx">
									<tr>
										<td :rowspan="getRowsCount(subitem)" class="text-center font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
											{{ indx + 1 }}
										</td>
										<td :rowspan="getRowsCount(subitem)" class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<div class="flex items-center mb-2">
												<div v-if="subitem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
												<div v-if="subitem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
											</div>
											{{ subitem.name }}
										</td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr v-if="subitem.ambulator">
										<td class="p-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">А</td>

										<td class="p-4 text-sm text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.a_Result }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.a_Count }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_1_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_1_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_1_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_2_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_2_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_2_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_3 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_3_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_3_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_3_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_4 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_4_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_4_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_4_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_5 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_5_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_5_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_5_3 }}
										</td>
									</tr>
									<tr v-if="subitem.stacionar">
										<td class="p-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">С</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_Result }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_Count }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_1_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_1_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_1_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_2_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_2_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_2_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_3 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_3_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_3_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_3_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_4 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_4_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_4_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_4_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_5 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_5_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_5_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_5_3 }}
										</td>
									</tr>
									<tr v-if="subitem.healthResort">
										<td class="p-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">К</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_Result }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_Count }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_1_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_1_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_1_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_2_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_2_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_2_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_3 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_3_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_3_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_3_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_4 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_4_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_4_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_4_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_5 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_5_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_5_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_5_3 }}
										</td>
									</tr>
									<tr v-if="subitem.psychiatric">
										<td class="p-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">П</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_Result }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_Count }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_1_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_1_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_1_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_2_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_2_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_2_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_3 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_3_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_3_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_3_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_4 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_4_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_4_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_4_3 }}
										</td>

										<td class="p-4 text-sm whitespace-no-wrap bg-lavender border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_5 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_5_1 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_5_2 }}
										</td>
										<td class="p-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_5_3 }}
										</td>
									</tr>
									<tr class="row-total">
										<td class="font-bold p-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">Итог</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_Result }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_Count }}
										</td>

										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_1 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_1_1 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_1_2 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_1_3 }}
										</td>

										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_2 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_2_1 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_2_2 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_2_3 }}
										</td>

										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_3 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_3_1 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_3_2 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_3_3 }}
										</td>

										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_4 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_4_1 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_4_2 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_4_3 }}
										</td>

										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_5 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_5_1 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_5_2 }}
										</td>
										<td class="p-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_5_3 }}
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>

			<div v-if="!loadingTable && data.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
				<span class="w-full">Данных нет...</span>
			</div>
		</div>
		<!-- Навигация -->
		<div class="h-auto rounded-b-lg bg-white filter shadow-default">
			<Pagination :rowsCount="totalRows" :pageSize="rowsVisible" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />
		</div>
		<!-- Прелоудер -->
		<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
			<loadingComponent class="absolute top-1/2 left-1/2" />
		</div>

		<PopUp :open="openExport" size="700px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="t-overflow flex flex-col overflow-auto" style="height: 850px">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="downloadExcell(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
// Глобальные данные стора
import { getters, actions, methods } from '@/store/store';
import { mutations } from '../store/mutations';
// Локальные данные стора
import { gettersLocal } from '../store/getters';
import actionsLocal from '../../NOCCurrentYear/store/actions';
import loadingComponent from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';
import Icons from '@/components/Icons.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';
import PopUp from '@/components/PopUp.vue';

export default {
	name: 'TableNOK',
	components: {
		loadingComponent,
		Pagination,
		FilterComponent,
		Button,
		Icons,
		CheckboxGroup,
		PopUp
	},
	computed: {
		...getters,
		...gettersLocal
	},
	data() {
		return {
			selectedTerm: null,
			selectedType: null,
			medicalTerms: [
				{ name: 'Амбулаторные', label: 'Амбулаторные' },
				{ name: 'Стационарные', label: 'Стационарные' },
				{ name: 'Санаторно-курортные', label: 'Санаторно-курортные' },
				{ name: 'Психиатрические больницы', label: 'Психиатрические больницы' }
			],
			questionaryTypes: [
				{ name: 'Все', label: 'Все' },
				{ name: 'Интерактивные', label: 'Интерактивные' },
				{ name: 'Бумажные', label: 'Бумажные' }
			],
			openExport: false,
			loadingTable: false,
			loadToExcell: false,
			circumference: 30 * 2 * Math.PI,
			rowOpened: {
				key: null,
				type: null
			},
			openContentChange: false,
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			filterOpen: null,
			filterBy: null,
			opened: null,
			fields: ['1', '1.1', '1.2', '1.3', '2', '2.1', '2.2', '2.3', '3', '3.1', '3.2', '3.3', '4', '4.1', '4.2', '4.3', '5', '5.1', '5.2', '5.3'],
			subFields: [
				'Открытость и доступность информации об организации',
				'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги',
				'Доступность услуг для инвалидов',
				'Доброжелательность, вежливость работников медицинской организации',
				'Удовлетворенность условиями оказания услуг'
			],
			fieldsDownloadExcell: [
				{ title: 'Филиал', id: 'Branch', checked: true },
				{ title: 'Наименование МО', id: 'Name', checked: true },
				{ title: 'Условия оказания', id: 'MOCondition', checked: true },
				{ title: 'Итоговый результат по условиям оказания', id: 'EachResult', checked: true },
				{ title: 'Количество анкет', id: 'EachCount', checked: true },
				{ title: 'Открытость и доступность информации об организации: 1', id: '1', checked: true },
				{ title: 'Открытость и доступность информации об организации: 1.1', id: '1_1', checked: true },
				{ title: 'Открытость и доступность информации об организации: 1.2', id: '1_2', checked: true },
				{ title: 'Открытость и доступность информации об организации: 1.3', id: '1_3', checked: true },
				{ title: 'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги: 2', id: '2', checked: true },
				{ title: 'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги: 2.1', id: '2_1', checked: true },
				{ title: 'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги: 2.2', id: '2_2', checked: true },
				{ title: 'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги: 2.3', id: '2_3', checked: true },
				{ title: 'Доступность услуг для инвалидов: 3', id: '3', checked: true },
				{ title: 'Доступность услуг для инвалидов: 3.1', id: '3_1', checked: true },
				{ title: 'Доступность услуг для инвалидов: 3.2', id: '3_2', checked: true },
				{ title: 'Доступность услуг для инвалидов: 3.3', id: '3_3', checked: true },
				{ title: 'Доброжелательность, вежливость работников медицинской организации: 4', id: '4', checked: true },
				{ title: 'Доброжелательность, вежливость работников медицинской организации: 4.1', id: '4_1', checked: true },
				{ title: 'Доброжелательность, вежливость работников медицинской организации: 4.2', id: '4_2', checked: true },
				{ title: 'Доброжелательность, вежливость работников медицинской организации: 4.3', id: '4_3', checked: true },
				{ title: 'Удовлетворенность условиями оказания услуг: 5', id: '5', checked: true },
				{ title: 'Удовлетворенность условиями оказания услуг: 5.1', id: '5_1', checked: true },
				{ title: 'Удовлетворенность условиями оказания услуг: 5.2', id: '5_2', checked: true },
				{ title: 'Удовлетворенность условиями оказания услуг: 5.3', id: '5_3', checked: true }
			],
			fields_sticky: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Условие оказания',
					fieldName: '',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Итоговый результат по условиям оказания',
					fieldName: 'r_Result',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Количество анкет',
					fieldName: 'r_Count',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			allRows: [],
			data: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				isRandom600: false,
				pageSize: this.$MaxPageSize,
				currentPage: 0
			}
		};
	},
	methods: {
		...actions,
		...actionsLocal,
		...methods,
		...mutations,
		getRandom() {
			this.getData(null, true);
		},
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalOrgId) {
					this.opened = null;
				} else {
					this.opened = item.medicalOrgId;
				}
			}
		},
		getRowsCount(item) {
			let count = 2;
			if (item.ambulator) count += 1;
			if (item.stacionar) count += 1;
			if (item.healthResort) count += 1;
			if (item.psychiatric) count += 1;
			return count;
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;

				this.params.filters.push({
					fieldName: 'YearStr',
					filterType: 2,
					filterValueType: 1,
					value1: this.getYear
				});

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getResultForYearByRegion(this.params).then((res) => {
					this.allRows = res.data.data;
					this.data = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.fields_sticky[0].list = res.data.searchData;

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
				});

				this.params.filters.pop();
			} else {
				this.data = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		closeOpenExport() {
			for (let i = 0; i < this.fieldsDownloadExcell.length; i += 1) {
				this.fieldsDownloadExcell[i].checked = true;
			}

			this.openExport = false;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		downloadExcell(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			params.filters.push({
				fieldName: 'YearStr',
				filterType: 2,
				filterValueType: 1,
				value1: this.getYear
			});
			this.$http
				.post(
					`MedicalOrgs/GetResultForYearExcel?isRandom600=${params.isRandom600}`,
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					params.filters.pop();
					let fileNameAndType = decodeURI(`${this.region.name}_Результаты НОК текущего года.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		downloadBusGovExcel() {
			this.loadToExcell = true;

			this.$http
				.post(
					`MedicalOrgs/GetBusGovRuExcel?RegionId=${this.region.id}&Year=${this.getYear}`,
					{},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_${this.getYear}_Таблица для busgov.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		columnHasGeneralIndicator(field) {
			let generalIndicators = ['1', '2', '3', '4', '5'];
			return generalIndicators.includes(field);
		}
	},
	mounted() {
		this.params.filters[0].value1 = this.region.id;

		if (this.getYear) {
			this.getData(null, true);
		} else {
			this.getRegionYearData(this.region.id).then((res) => {
				this.setYear(res.data.previousYear);
				this.getData(null, true);
			});
		}
	}
};
</script>

<style scoped>
.tr1_sticky {
	background-color: #f9fafb;
	height: 57px;
	top: 0;
	position: sticky;
}
.tr2_sticky {
	background-color: #f9fafb;
	height: 113px;
	top: 57px;
	position: sticky;
}
.tr3_sticky {
	background-color: #f9fafb;
	height: 49px;
	top: 170px;
	position: sticky;
}
.t-overflow {
	overflow: auto;
	min-height: 300px;
	max-width: 100vw;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.row-total,
.row-total td {
	background-color: #e2efda;
}
</style>
