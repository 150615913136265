<template>
	<div class="flex flex-col relative">
		<div class="h-auto filter mb-5">
			<div class="w-full rounded-lg flex flex-wrap items-center justify-between gap-5">
				<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Архив</h1>
				<div class="flex items-center justify-end gap-5" v-if="getYears">
					<Select v-model="selectedYear" :options="getYears.closedYears" @input="setData(selectedYear)" :reduce="(option) => option" color="blue" size="small" />
				</div>
			</div>
		</div>
		<div>
			<div>
				<ul class="block p-0 list-none">
					<div class="border-b-2 border-dopBlue w-max">
						<li
							v-for="(tab, index) in tabs"
							:key="'F' + index"
							@click="selectedIndex = index"
							class="h-14 py-3.5 px-7 m-0 inline-block mr-2.5 cursor-pointer rounded-t-lg border-t border-r border-l text-sm leading-4 font-bold tracking-wider last:mr-0"
							:class="selectedIndex === index ? 'text-white bg-dopBlue border-dopBlue' : 'text-gray-400 bg-transparent border-gray-400'"
						>
							<div class="flex items-center justify-center h-full">
								{{ tab }}
							</div>
						</li>
					</div>
				</ul>
				<div class="inline-block w-full p-0">
					<TableMO ref="tableMO" v-if="selectedIndex === 0" />
					<TableOS ref="tableOS" v-if="selectedIndex === 1" />
					<TableResults ref="TableResults" v-if="selectedIndex === 2" />
					<TableNOK ref="TableNOK" v-if="selectedIndex === 3" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mutations } from '../store/mutations';
import { getters, actions } from '@/store/store';
import Select from '@/components/Select.vue';
import TableMO from './TableMO.vue';
import TableOS from './TableOS.vue';
import TableResults from './TableResults.vue';
import TableNOK from './TableNOK.vue';

export default {
	name: 'TableMain',
	components: {
		Select,
		TableMO,
		TableOS,
		TableResults,
		TableNOK
	},
	data() {
		return {
			selectedYear: null,
			selectedIndex: 0,
			tabs: ['Перечень МО', 'Решения ОС', 'Результаты анкетирования', 'Результаты НОК']
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		setData() {
			this.setYear(this.selectedYear);
			if (this.$refs.tableMO) this.$refs.tableMO.getData();
			if (this.$refs.tableOS) this.$refs.tableOS.getData();
			if (this.$refs.TableResults) this.$refs.TableResults.getData();
			if (this.$refs.TableNOK) this.$refs.TableNOK.getData();
		}
	},
	created() {
		this.getRegionYearData(this.region.id).then((res) => {
			this.selectedYear = res.data.previousYear;
		});
	}
};
</script>
