// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';

export default {
	getAllMedicalOrgsForRegions(regionId) {
		let params = {
			filters: [
				{
					fieldName: 'RegionId',
					filterType: 1,
					filterValueType: 1,
					value1: regionId
				}
			],
			sortOption: {
				fieldName: 'RegionName',
				sortType: 2
			},
			pageSize: 999,
			currentPage: 0
		};
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetMedicalOrgs', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getResultForYearByRegion(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`MedicalOrgs/GetLKOGVForYear?isRandom600=${parametrs.isRandom600}`, parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getIncludedMO(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetIncludedMO', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getOSDecsisionAS(parametrs, region) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`OS/GetOSDecsisionAS?regionId=${region}`, parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getOSDecsisionSP(parametrs, region) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`OS/GetOSDecsisionSP?regionId=${region}`, parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	updateOSDecsisionAS(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.put('OS/UpdateOSDecsisionAS', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	updateOSDecsisionSP(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.put('OS/UpdateOSDecsisionSP', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	blockUnBlockFullDecision(parametrs) {
		let block = parametrs.flag ? 'OS/BlockFullDecision' : 'OS/UnBlockFullDecision';
		return new Promise((resolve, reject) => {
			this.$http
				.post(`${block}?year=${parametrs.year}&type=${parametrs.type}&regionId=${parametrs.regionId}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMOAnketResults(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetMOAnketResults', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
