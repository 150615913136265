<template>
	<div class="flex flex-col relative">
		<div class="absolute right-0 -top-14">
			<Button text="Экспорт в Excel" icon="exel" @click.native="openExport = true" />
		</div>
		<div class="t-overflow rounded-tr-lg overflow-x-auto bg-white filter shadow-default" style="height: calc(100vh - 424px)">
			<table class="table-fixed w-full" :style="data.length > 0 ? 'min-height: 550px' : ''">
				<thead>
					<tr class="tr1__sticky">
						<th rowspan="2" class="w-20 px-6 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider">П/П</th>
						<th rowspan="2" class="first-cell px-1 py-4 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider">
							<span class="flex relative">
								<span class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === 0 }" @click="switchFilterVisibility(0)"> Наименование МО </span>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[0].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
								<!-- Фильтр и сортировка -->
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									position="left"
									:filterOpen="filterOpen"
									:id-filter="0"
									:param="params"
									@query="getData"
									:fields="fields"
									:item="fields[0]"
								/>
							</span>
						</th>
						<th colspan="5" class="second-cell px-6 py-4 border-b border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider">
							<p>Количество заполненных анкет</p>
						</th>
					</tr>
					<tr class="tr2__sticky">
						<th
							v-for="(item, ind) in fields.slice(1)"
							:key="'J' + ind"
							class="px-1 py-4 border-b border-r border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider"
						>
							<p class="break-words">{{ item.Name }}</p>
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, indx) in data" :key="'A' + indx">
					<tr :class="{ 'bg-gray-30': item.medicalOrgId === opened, 'cursor-pointer': item.hasBranch }" @click="openRow(item)">
						<td class="text-center font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
							<span v-if="params.currentPage === 0">
								{{ indx + 1 }}
							</span>
							<span v-if="params.currentPage > 0">
								{{ params.currentPage * rowsVisible + (indx + 1) }}
							</span>
						</td>
						<td
							v-for="(field, ind) in fields"
							:key="'L' + ind"
							class="px-1 py-4 font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500"
							:class="{ 'border-none': item.medicalOrgId === opened, 'text-left': ind === 0, 'text-center': ind !== 0 }"
						>
							<div v-if="ind === 0" class="flex items-center mb-2">
								<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
								<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
							</div>

							<p :class="{ underline: item.hasBranch }">
								{{ item[field.fieldName] }}
							</p>
						</td>
					</tr>
					<tr v-if="opened === item.medicalOrgId">
						<td colspan="7" class="bg-gray-20">
							<table class="w-full table-fixed">
								<tbody v-for="(subitem, indx) in item.branches" :key="'N' + indx">
									<tr>
										<td style="width: 80px" class="text-center font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
											{{ indx + 1 }}
										</td>
										<td
											v-for="(field, ind) in fields"
											:key="'K' + ind"
											class="px-1 py-4 font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500"
											:class="{ 'text-center': ind !== 0, 'first-cell text-left': ind === 0 }"
										>
											<div v-if="ind === 0" class="flex items-center mb-2">
												<div v-if="subitem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
												<div v-if="subitem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
											</div>
											<p>
												{{ subitem[field.fieldName] }}
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<div v-if="!loadingTable && data.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
				<span class="w-full">Данных нет...</span>
			</div>
		</div>
		<!-- Навигация -->
		<div class="h-auto rounded-b-lg bg-white filter shadow-default">
			<Pagination :rowsCount="totalRows" :pageSize="rowsVisible" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />
		</div>
		<!-- Прелоудер -->
		<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
			<loadingComponent class="absolute top-1/2 left-1/2" />
		</div>

		<PopUp :open="openExport" size="500px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="flex flex-col">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="downloadExcell(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
// Глобальные данные стора
import { getters, methods, actions } from '@/store/store';
// Локальные данные стора
import { gettersLocal } from '../store/getters';
import { mutations } from '../store/mutations';
import action from '../../NOCCurrentYear/store/actions';
import loadingComponent from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';
import Icons from '@/components/Icons.vue';
import PopUp from '@/components/PopUp.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';

export default {
	name: 'TableMO',
	components: {
		loadingComponent,
		Pagination,
		FilterComponent,
		Button,
		CheckboxGroup,
		PopUp,
		Icons
	},
	computed: {
		...gettersLocal,
		...getters
	},
	data() {
		return {
			openExport: false,
			loadingTable: false,
			loadToExcell: false,
			circumference: 30 * 2 * Math.PI,
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			filterOpen: null,
			filterBy: null,
			opened: null,
			fieldsDownloadExcell: [
				{ title: 'Филиал', id: 'Branch', checked: true },
				{ title: 'Наименование МО', id: 'Name', checked: true },
				{ title: 'Количество заполненных анкет: Общее количество', id: 'AllCount', checked: true },
				{ title: 'Количество заполненных анкет: Амбулаторные', id: 'AmbulatorCount', checked: true },
				{ title: 'Количество заполненных анкет: Стационарные', id: 'StacionarCount', checked: true },
				{ title: 'Количество заполненных анкет: Санаторно-курортные', id: 'SanatoriatCount', checked: true },
				{ title: 'Количество заполненных анкет: Психиатрические', id: 'PsychiatricCount', checked: true }
			],
			fields: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Общее количество',
					fieldName: 'allCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulatorCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionarCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'sanatoriatCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatricCount',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			allRows: [],
			data: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'YearStr',
						filterType: 2,
						filterValueType: 2,
						value1: '2022'
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				columns: [],
				pageSize: this.$MaxPageSize,
				currentPage: 0
			}
		};
	},
	methods: {
		...action,
		...methods,
		...mutations,
		getRegionYearData: actions.getRegionYearData,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalOrgId) {
					this.opened = null;
				} else {
					this.opened = item.medicalOrgId;
				}
			}
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;
				this.params.filters[1].value1 = this.getYear;

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getIncludedMO(this.params).then((res) => {
					this.allRows = res.data.data;
					this.data = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.fields[0].list = res.data.searchData;

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
				});
			} else {
				this.data = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		closeOpenExport() {
			for (let i = 0; i < this.fieldsDownloadExcell.length; i += 1) {
				this.fieldsDownloadExcell[i].checked = true;
			}

			this.openExport = false;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		downloadExcell(params, fieldsDownloadExcell) {
			this.params.filters[1].value1 = this.getYear;
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.$http
				.post(
					'MedicalOrgs/GetIncludedMOExcel',
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Перечень медицинских организаций текущего года.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		}
	},
	created() {
		this.params.filters[0].value1 = this.region.id;

		this.getRegionYearData(this.region.id).then((res) => {
			this.setYear(res.data.previousYear);
			this.getData(null, true);
		});
	}
};
</script>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
}
.tr2__sticky {
	background-color: #f9fafb;
	top: 48px;
	position: sticky;
}
.first-cell {
	width: 700px;
}

.t-overflow {
	overflow: auto;
	max-width: 100vw;
	min-height: 300px;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
