<template>
	<div class="input-box" :class="{ error: Boolean(error) }">
		<label :for="_uid">{{ label }}</label>
		<v-select
			:value="value"
			@input="updateInput"
			label="label"
			:reduce="(option) => option"
			:class="{ disabled: disabled, 'select-blue': color === 'blue', 'select-small': size === 'small' }"
			:options="options"
			:disabled="disabled"
			v-bind="$attrs"
		></v-select>
		<p class="note">{{ error }}</p>
	</div>
</template>

<script>
export default {
	name: 'Select',
	props: {
		label: String,
		error: String,
		disabled: Boolean,
		options: Array,
		value: Number,
		color: String,
		size: String
	},
	methods: {
		updateInput(event) {
			this.$emit('input', event);
		}
	}
};
</script>

<style>
.note {
	display: none;
	font-size: 12px;
}

.error .note {
	display: block;
	color: red;
}

.input-box {
	position: relative;
	margin-bottom: 20px;
}

.select-icon {
	position: absolute;
	right: 5px;
	top: 42px;
	transition: 0.3s;
}

.input-box label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.error.input-box input {
	border-color: red;
}

.input-box input:focus svg path {
	stroke: #3377ff !important;
}

.input-box input.disabled {
	background: #f2f5ff;
}

.select-blue > .vs__dropdown-toggle {
	border: 2px solid #3377ff;
	font-weight: 700;
	color: #3377ff;
	background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1' stroke='%233377FF' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E")
		no-repeat 90% 50%;
}

.select-small.v-select {
	min-width: 106px !important;
	width: 100%;
	height: 44px;
}

.select-small.v-select .vs__selected {
	text-align: center;
}

.select-small.v-select ul.vs__dropdown-menu {
	text-align: center;
}
</style>
