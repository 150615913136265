<template>
	<div class="radio-group">
		<div class="flex flex-col gap-5">
			<label for="all1" class="w-auto block text-sm text-gray-500 font-normal" :class="{ 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled }">
				<input
					:class="{ 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled }"
					id="all1"
					:disabled="disabled"
					type="checkbox"
					v-model="all"
					@change="selectedAll(all, options)"
					:style="all ? 'background-color:#3377ff;border: 2px solid #3377ff;' : ''"
				/>
				Все
			</label>
			<label
				v-for="option in options"
				:key="option.id"
				:for="option.id"
				class="w-auto block text-sm text-gray-500 font-normal"
				:class="{ 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled }"
			>
				<input :class="{ 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled }" type="checkbox" :id="option.id" :disabled="disabled" v-model="option.checked" @change="checkAll" />
				{{ option.title }}
			</label>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RadioGroup',
	props: {
		options: Array,
		disabled: {
			type: Boolean,
			default: () => true
		}
	},
	data: () => ({
		all: true
	}),
	methods: {
		selectedAll(value, options) {
			for (let i = 0; i < options.length; i += 1) {
				options[i].checked = value;
			}
		},
		checkAll() {
			for (let i = 0; i < this.options.length; i += 1) {
				if (!this.options[i].checked) {
					this.all = false;

					break;
				}
			}
		}
	}
};
</script>

<style scoped>
input[type='checkbox'] {
	appearance: none;
	background-color: #fff;
	width: 20px;
	height: 20px;
	border: 2px solid #a8b1ce;
	border-radius: 2px;
	display: inline-grid;
	place-content: center;
	margin-right: 6px;
}

input[type='checkbox']::before {
	content: '';
	width: 10px;
	height: 10px;
	transform: scale(0);
	transform-origin: bottom left;
	background-color: #fff;
	clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

input[type='checkbox']:checked::before {
	transform: scale(1);
}
input[type='checkbox']:checked {
	background-color: #3377ff;
	border: 2px solid #3377ff;
}
input[type='checkbox']:checked:disabled {
	background-color: #bec7da;
	border: 2px solid #bec7da;
}
</style>
