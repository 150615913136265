<template>
	<div class="flex flex-col relative">
		<div class="absolute right-0 -top-14">
			<div class="flex items-center">
				<Button
					@click.native="upLoadSaveExcel(params)"
					text="Скачать"
					v-if="getYear == 2022"
					icon="upload"
					class="mr-2"
					color="white"
					spinnerColor="rgba(59, 130, 246, 255)"
					:disabled="loadToExcell"
					:load="loadToExcell"
				/>
				<Button text="Экспорт в Excel" icon="exel" @click.native="openDialogExport" />
			</div>
		</div>
		<div class="h-auto rounded-tr-lg bg-white filter shadow-default">
			<div class="p-4 flex items-center">
				<span class="mr-3 text-sm text-gray-500 tracking-wider leading-4 font-semibold"> Мед. условия </span>
				<div class="mr-5 w-72">
					<v-select :options="medArr" v-model="params.filters[1].fieldName" :reduce="(option) => option.value" @input="getData(null, true)"></v-select>
				</div>
				<span class="mr-3 text-sm text-gray-500 tracking-wider leading-4 font-semibold"> Тип анкеты </span>
				<div class="w-56">
					<v-select :options="anketType" v-model="params.filters[2].value1" :reduce="(option) => option.value" @input="getData(null, true)"></v-select>
				</div>
			</div>
		</div>
		<div class="t-overflow rounded-tr-lg overflow-x-auto h-auto bg-white filter shadow-default" style="height: calc(100vh - 494px)">
			<table class="table-fixed w-full" :style="data.length > 0 ? 'min-height: 550px' : ''">
				<colgroup>
					<col style="width: 4.2rem" />
					<col style="min-width: 18.8rem" />
					<col style="width: 7.8rem" />
					<col style="width: 9rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
					<col style="width: 4.2rem" />
				</colgroup>
				<thead>
					<tr class="border-t border-gray-200 tr1_sticky">
						<th rowspan="3" class="p-4 border-b border-r border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider">П/П</th>
						<th
							v-for="(item, ind) in fields_sticky"
							:key="'J' + ind"
							rowspan="3"
							class="p-4 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider"
						>
							<span class="flex relative">
								<p class="underline cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
									{{ item.Name }}
								</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
								<!-- Фильтр и сортировка -->
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									position="left"
									:filterOpen="filterOpen"
									:id-filter="ind"
									:param="params"
									@query="getData"
									:fields="fields_sticky"
									:item="item"
								/>
							</span>
						</th>
						<th colspan="13" class="p-4 border-r border-b border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider">
							<p>Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка</p>
						</th>
					</tr>
					<tr class="tr2_sticky">
						<th
							v-for="(item, ind) in subFields"
							:key="'K' + ind"
							:colspan="ind === 0 ? 1 : 3"
							class="p-4 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider"
						>
							<p>{{ item }}</p>
						</th>
					</tr>
					<tr class="tr3_sticky">
						<th v-for="(item, ind) in fields" :key="'L' + ind" class="p-4 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider">
							<p>{{ item }}</p>
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, ind) in data" :key="'A2' + ind" class="bg-white">
					<tr :class="{ 'bg-gray-30': item.medicalId === opened, 'cursor-pointer': item.hasBranch }" @click="openRow(item)">
						<td class="text-center font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
							<span v-if="params.currentPage === 0">
								{{ ind + 1 }}
							</span>
							<span v-if="params.currentPage > 0">
								{{ params.currentPage * rowsVisible + (ind + 1) }}
							</span>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200" :class="{ 'underline text-dopBlue': item.medicalId === opened }">
							<div class="flex items-center mb-2">
								<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
								<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
							</div>
							<p :class="{ underline: item.hasBranch }">{{ item.name }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item.eachCount }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._1_3 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._2_1 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._2_2 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._2_3 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._3_1 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._3_2 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._3_3 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._4_1 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._4_2 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._4_3 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._5_1 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._5_2 }}</p>
						</td>
						<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._5_3 }}</p>
						</td>
					</tr>
					<tr v-if="opened === item.medicalId">
						<td colspan="16" class="bg-gray-20">
							<table class="w-full table-fixed">
								<colgroup>
									<col style="width: 4.2rem" />
									<col style="min-width: 18.8rem" />
									<col style="width: 7.8rem" />
									<col style="width: 9rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
									<col style="width: 4.2rem" />
								</colgroup>
								<tbody v-for="(subitem, indx) in item.branches" :key="'N' + indx">
									<tr>
										<td class="text-center font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
											{{ indx + 1 }}
										</td>
										<td
											class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200"
											:class="{ 'underline text-dopBlue': subitem.medicalId === opened }"
										>
											<div class="flex items-center mb-2">
												<div v-if="subitem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
												<div v-if="subitem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
											</div>
											<p>{{ subitem.name }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.eachCount }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._1_3 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._2_1 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._2_2 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._2_3 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._3_1 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._3_2 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._3_3 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._4_1 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._4_2 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._4_3 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._5_1 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._5_2 }}</p>
										</td>
										<td class="p-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem._5_3 }}</p>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>

			<div v-if="!loadingTable && data.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
				<span class="w-full">Данных нет...</span>
			</div>
		</div>
		<!-- Навигация -->
		<div class="h-auto rounded-b-lg bg-white filter shadow-default">
			<Pagination :rowsCount="totalRows" :pageSize="rowsVisible" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />
		</div>
		<!-- Прелоудер -->
		<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
			<loadingComponent class="absolute top-1/2 left-1/2" />
		</div>

		<PopUp :open="openInfo" @close="openInfo = false" title="Внимание...">
			<p>Приносим извинение. Идет загрузка анкет. Выгрузка станет доступна в ближайшее время.</p>
		</PopUp>

		<PopUp :open="openExport" size="700px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="flex flex-col">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="downloadExcell(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
// Глобальные данные стора
import { getters, methods, actions } from '@/store/store';
import { mutations } from '../store/mutations';
// Локальные данные стора
import { gettersLocal } from '../store/getters';
import PopUp from '@/components/PopUp.vue';
import action from '../../NOCCurrentYear/store/actions';
import loadingComponent from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';
import Icons from '@/components/Icons.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';

export default {
	name: 'TableResults',
	components: {
		loadingComponent,
		Pagination,
		FilterComponent,
		Button,
		Icons,
		PopUp,
		CheckboxGroup
	},
	computed: {
		...getters,
		...gettersLocal
	},
	data() {
		return {
			openExport: false,
			openInfo: false,
			selectTerm: null,
			selectedType: null,
			medArr: [
				{ value: 'isAmbulator', label: 'Амбулаторные' },
				{ value: 'isStacionar', label: 'Стационарные' },
				{ value: 'isSanatoriat', label: 'Санаторно-курортные' },
				{ value: 'isPsychiatric', label: 'Психиатрические больницы' }
			],
			anketType: [
				{ value: '3', label: 'Все' },
				{ value: '2', label: 'Интерактивные' },
				{ value: '1', label: 'Бумажные' }
			],
			loadingTable: false,
			loadToExcell: false,
			circumference: 30 * 2 * Math.PI,
			rowOpened: {
				key: null,
				type: null
			},
			openContentChange: false,
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			filterOpen: null,
			filterBy: null,
			opened: null,
			fields: ['1.3', '2.1', '2.2', '2.3', '3.1', '3.2', '3.3', '4.1', '4.2', '4.3', '5.1', '5.2', '5.3'],
			subFields: [
				'Открытость и доступность информации об организации',
				'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги',
				'Доступность услуг для инвалидов',
				'Доброжелательность, вежливость работников медицинской организации',
				'Удовлетворенность условиями оказания услуг'
			],
			fields_sticky: [
				{
					Name: 'Наименование МО',
					fieldName: 'Name',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Количество анкет',
					fieldName: 'TotalCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			fieldsDownloadExcell: [],
			allRows: [],
			data: [],
			openName: false,
			openChange: false,
			openFIO: true,
			filterData: {
				sortBy: '',
				searchValue: ''
			},
			openAllChange: false,
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'isAmbulator',
						filterType: 1,
						filterValueType: 1,
						value1: 'true'
					},
					{
						fieldName: 'VoteType',
						filterType: 1,
						filterValueType: 1,
						value1: '3'
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: this.$MaxPageSize,
				currentPage: 0,
				columns: []
			}
		};
	},
	methods: {
		...action,
		...methods,
		...mutations,
		getRegionYearData: actions.getRegionYearData,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalId) {
					this.opened = null;
				} else {
					this.opened = item.medicalId;
				}
			}
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		iterationParams() {
			this.fieldsDownloadExcell = [
				{ title: 'Филиал', id: 'Branch', checked: true },
				{ title: 'Наименование МО', id: 'MedOrgName', checked: true },
				{ title: 'Количество анкет', id: 'SurveysCount', checked: true }
			];

			if (this.params.sortOption.sortType) {
				let formTypeId = '';
				if (this.params.filters[1].fieldName === 'isAmbulator') formTypeId = 'AmbulatorRes';
				if (this.params.filters[1].fieldName === 'isStacionar') formTypeId = 'StacionarRes';
				if (this.params.filters[1].fieldName === 'isSanatoriat') formTypeId = 'SanatoriatRes';
				if (this.params.filters[1].fieldName === 'isPsychiatric') formTypeId = 'PsychaitricRes';
			}

			for (let i = 0; i < this.fields_sticky.length; i += 1) {
				let Name = this.fields_sticky[i].Name,
					PFN = this.params.filters[1].fieldName;

				// console.log(Name, PFN, this.fields_sticky[i]);
				if (Name === 'Количество анкет' && PFN === 'isAmbulator') this.fields_sticky[i].fieldName = 'ambulatorcount';
				if (Name === 'Количество анкет' && PFN === 'isStacionar') this.fields_sticky[i].fieldName = 'StacionarCount';
				if (Name === 'Количество анкет' && PFN === 'isSanatoriat') this.fields_sticky[i].fieldName = 'sanatoriatcount';
				if (Name === 'Количество анкет' && PFN === 'isPsychiatric') this.fields_sticky[i].fieldName = 'PsychiatricCount';
			}
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;

				this.params.filters.push({
					fieldName: 'YearStr',
					filterType: 2,
					filterValueType: 1,
					value1: this.getYear
				});

				this.iterationParams();

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getMOAnketResults(this.params).then((res) => {
					this.allRows = res.data.data;
					this.data = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.fields_sticky[0].list = res.data.searchData;

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
				});

				this.params.filters.pop();
			} else {
				this.data = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		downloadExcell(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 2,
				filterValueType: 1,
				value1: this.getYear
			});
			this.$http
				.post(
					'MedicalOrgs/GetMOAnketResultsExcel',
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Результаты анкетирования.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
			params.filters.pop();
		},
		upLoadSaveExcel(params) {
			this.loadToExcell = true;
			let saveParam = {};
			params.filters.forEach((item) => {
				if (item.fieldName === 'RegionId') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'YearStr') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'VoteType') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'isAmbulator') {
					saveParam.formTypeId = '111';
					saveParam.name = 'Амбулаторные';
				}
				if (item.fieldName === 'isStacionar') {
					saveParam.formTypeId = '115';
					saveParam.name = 'Стационарные';
				}
				if (item.fieldName === 'isSanatoriat') {
					saveParam.formTypeId = '114';
					saveParam.name = 'Санаторно-курортные';
				}
				if (item.fieldName === 'isPsychiatric') {
					saveParam.formTypeId = '113';
					saveParam.name = 'Психиатрические больницы';
				}
			});
			this.$http
				.get(`Anketa/GetAnketaReportExcel?RegionId=${saveParam.RegionId}&FormTypeId=${saveParam.formTypeId}&paperTypeId=${saveParam.VoteType}&year=${this.getYear}`, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_${saveParam.name}_${this.getYear}_.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		closeOpenExport() {
			this.fieldsDownloadExcell = [];
			this.openExport = false;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		openDialogExport() {
			this.iterationParams(this.params);

			for (let i = 0; i < this.fields.length; i += 1) {
				let text = '';

				if (i === 0) text = 'Открытость и доступность информации об организации:';
				if (i > 0 && i <= 3) text = 'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги:';
				if (i > 3 && i <= 6) text = 'Доступность услуг для инвалидов:';
				if (i > 6 && i <= 9) text = 'Доброжелательность, вежливость работников медицинской организации:';
				if (i > 9 && i <= 12) text = 'Удовлетворенность условиями оказания услуг:';

				let FN = this.fields[i];

				this.fieldsDownloadExcell.push({
					title: `${text} ${this.fields[i]}`,
					id: `Value_${FN.split('.')[0]}_${FN.split('.')[1]}`,
					checked: true
				});
			}

			this.openExport = true;
		}
	},
	mounted() {
		this.params.filters[0].value1 = this.region.id;

		if (this.getYear) {
			this.getData(null, true);
		} else {
			this.getRegionYearData(this.region.id).then((res) => {
				this.setYear(res.data.previousYear);
				this.getData(null, true);
			});
		}
	}
};
</script>

<style scoped>
.tr1_sticky {
	background-color: #f9fafb;
	height: 65px;
	top: 0;
	position: sticky;
}
.tr2_sticky {
	background-color: #f9fafb;
	height: 145px;
	top: 65px;
	position: sticky;
}
.tr3_sticky {
	background-color: #f9fafb;
	height: 49px;
	top: 210px;
	position: sticky;
}

.t-overflow {
	overflow: auto;
	min-height: 300px;
	max-width: 100vw;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
