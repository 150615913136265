<template>
	<div>
		<div class="flex flex-col relative">
			<div class="h-auto filter mb-5">
				<div class="w-full flex items-center justify-end">
					<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" />
				</div>
			</div>
			<div class="h-auto rounded-t-lg bg-white filter shadow-default">
				<div class="px-6 py-4 text-lg text-black font-bold">Для санаторно-курортных организаций и психиатрических больниц</div>
			</div>
			<div class="t-overflow overflow-x-auto h-auto bg-white filter shadow-default">
				<table class="w-full table-fixed" :style="data.length > 0 ? 'min-height: 550px' : ''">
					<colgroup>
						<col style="width: 4.9rem" />
						<col style="min-width: 18.8rem" />
						<col style="width: 9.4rem" />
						<col style="width: 8rem" />
						<col style="width: 4.6rem" />
						<col style="width: 4.6rem" />
						<col style="width: 11rem" />
						<col style="width: 4.6rem" />
						<col style="width: 4.6rem" />
					</colgroup>
					<thead class="border-t border-gray-200">
						<tr>
							<th rowspan="3" class="px-6 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider">П/П</th>
							<th
								v-for="(item, ind) in fields_sticky_second"
								:key="'J' + ind"
								rowspan="3"
								class="px-6 py-3 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider"
							>
								<span class="flex relative">
									<p class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
										{{ item.Name }}
									</p>
									<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
									<!-- Фильтр и сортировка -->
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										position="left"
										:filterOpen="filterOpen"
										:id-filter="ind"
										:param="params"
										@query="getData"
										:fields="fields_sticky_second"
										:item="item"
									/>
								</span>
							</th>
							<th colspan="5" class="px-6 py-3 border-r border-b border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider">
								Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка
							</th>
						</tr>
						<tr>
							<th
								v-for="(item, ind) in subFieldsSecond"
								:key="'K' + ind"
								:colspan="ind === 1 ? '1' : '2'"
								class="px-6 py-4 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider"
							>
								<p>{{ item }}</p>
							</th>
						</tr>
						<tr>
							<th
								v-for="(item, ind) in fieldsSecond"
								:key="'L' + ind"
								class="px-6 py-4 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider"
							>
								<p>{{ item }}</p>
							</th>
						</tr>
					</thead>
					<tbody v-for="(item, indx) in data" :key="'A' + indx">
						<tr :class="{ 'bg-gray-30': item.id === opened, 'cursor-pointer': item.hasBranch }" @click="openRow(item)">
							<td class="text-center font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span v-if="params.currentPage === 0">
									{{ indx + 1 }}
								</span>
								<span v-if="params.currentPage > 0">
									{{ params.currentPage * rowsVisible + (indx + 1) }}
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b text-left border-gray-200 leading-5 text-gray-500">
								<div class="flex items-center mb-2">
									<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
									<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
								</div>
								<p :class="{ underline: item.hasBranch }">
									{{ item.medicalOrgName }}
								</p>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<p>
									{{ item.protokolNumber }}
								</p>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<p>
									{{ item.protokolDate }}
								</p>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<p>
									{{ item.value1_1 }}
								</p>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<p>
									{{ item.value1_2 }}
								</p>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<p>
									{{ item.value2_2 }}
								</p>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<p>
									{{ item.value3_1 }}
								</p>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<p>
									{{ item.value3_2 }}
								</p>
							</td>
						</tr>
						<tr v-if="opened === item.id">
							<td colspan="9" class="bg-gray-20">
								<table class="w-full table-fixed">
									<colgroup>
										<col style="width: 4.9rem" />
										<col style="min-width: 18.8rem" />
										<col style="width: 9.4rem" />
										<col style="width: 8rem" />
										<col style="width: 4.6rem" />
										<col style="width: 4.6rem" />
										<col style="width: 11rem" />
										<col style="width: 4.6rem" />
										<col style="width: 4.6rem" />
									</colgroup>
									<tbody v-for="(subitem, indx) in item.branches" :key="'N' + indx">
										<tr>
											<td class="text-center font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												{{ indx + 1 }}
											</td>
											<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b text-left border-gray-200 leading-5 text-gray-500">
												<div class="flex items-center mb-2">
													<div v-if="subitem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
													<div v-if="subitem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
												</div>
												<p>
													{{ subitem.medicalOrgName }}
												</p>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<p>
													{{ subitem.protokolNumber }}
												</p>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<p>
													{{ subitem.protokolDate }}
												</p>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<p>
													{{ subitem.value1_1 }}
												</p>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<p>
													{{ subitem.value1_2 }}
												</p>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<p>
													{{ subitem.value2_2 }}
												</p>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<p>
													{{ subitem.value3_1 }}
												</p>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<p>
													{{ subitem.value3_2 }}
												</p>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<div v-if="!loadingTable && data.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
					<span class="w-full">Данных нет...</span>
				</div>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<Pagination :rowsCount="totalRows" :pageSize="rowsVisible" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />
			</div>
			<!-- Прелоудер -->
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
	</div>
</template>

<script>
// Глобальные данные стора
import { getters, methods } from '@/store/store';
// Локальные данные стора
import { gettersLocal } from '../store/getters';
import actions from '../../NOCCurrentYear/store/actions';
import loadingComponent from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';
import Icons from '@/components/Icons.vue';

export default {
	name: 'TableOSSecond',
	components: {
		loadingComponent,
		Pagination,
		FilterComponent,
		Button,
		Icons
	},
	computed: {
		...getters,
		...gettersLocal
	},
	data() {
		return {
			loadingTable: false,
			loadToExcell: false,
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			filterOpen: null,
			filterBy: null,
			fieldsSecond: ['1.1', '1.2', '2.2', '3.1', '3.2'],
			subFieldsSecond: [
				'Открытость и доступность информации об организации',
				'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги',
				'Доступность услуг для инвалидов'
			],
			fields_sticky_second: [
				{
					Name: 'Наименование МО',
					fieldName: 'medicalOrgName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'medicalOrgName',
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '№ протокола',
					fieldName: 'protokolNumber',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Дата протокола',
					fieldName: 'protokolDate',
					filterType: 3,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			opened: null,
			allRows: [],
			data: [],
			filterData: {
				sortBy: '',
				searchValue: ''
			},
			openAllChange: false,
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: this.$MaxPageSize,
				currentPage: 0
			}
		};
	},
	methods: {
		...actions,
		...methods,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.id) {
					this.opened = null;
				} else {
					this.opened = item.id;
				}
			}
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;

				this.params.filters.push({
					fieldName: 'YearStr',
					filterType: 2,
					filterValueType: 1,
					value1: this.getYear
				});

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getOSDecsisionSP(this.params, this.region.id).then((res) => {
					this.allRows = res.data.data;
					this.data = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.fields_sticky_second[0].list = res.data.searchData;

					this.allRows.map((item) => {
						this.initializeItem(item);
						item.branches.forEach((x) => this.initializeItem(x));
						return item;
					});

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
				});

				this.params.filters.pop();
			} else {
				this.data = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		initializeItem(item) {
			item.update = false;
			item.protokolDate = item.protokolDate ? this.$moment(item.protokolDate).format('YYYY-MM-DD') : '---';
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		downloadExcell(params) {
			this.loadToExcell = true;

			params.filters.push({
				fieldName: 'YearStr',
				filterType: 2,
				filterValueType: 1,
				value1: this.getYear
			});
			this.$http
				.post(
					'OS/GetOSDecsisionSPExcel',
					{
						...params
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Санаторно-курортных организаций и психиатрических больниц.xlsx`);
					params.filters.pop();

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		}
	},
	mounted() {
		this.params.filters[0].value1 = this.region.id;
	}
};
</script>

<style scoped>
.t-overflow {
	overflow: auto;
	min-height: 300px;
	max-width: 100vw;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
